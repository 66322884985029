
import { Options, Vue } from 'vue-class-component';
import { Emit, Prop, Watch } from '@/helpers/Decorators';
import { baseurl } from '@/helpers/Utils';
import Permissions from '@/settings/permissions';
import StorageService from '@/modules/core/files/services/StorageService';
import merge from 'lodash/merge';
import head from 'lodash/head';
import * as xss from 'xss';

// Import TinyMCE
import tinymce from 'tinymce/tinymce';

// Any plugins you want to use has to be imported
import 'tinymce/plugins/advlist';
import 'tinymce/plugins/wordcount';
import 'tinymce/plugins/autolink';
import 'tinymce/plugins/autosave';
import 'tinymce/plugins/charmap';
import 'tinymce/plugins/emoticons';
import 'tinymce/icons/default';
// import 'tinymce/plugins/codesample';
import 'tinymce/plugins/fullscreen';
import 'tinymce/plugins/hr';
// import 'tinymce/plugins/imagetools';
import 'tinymce/plugins/insertdatetime';
import 'tinymce/plugins/link';
import 'tinymce/plugins/media';
// import 'tinymce/plugins/noneditable';
import 'tinymce/plugins/paste';
// import 'tinymce/plugins/print';
import 'tinymce/plugins/searchreplace';
// import 'tinymce/plugins/tabfocus';
import 'tinymce/plugins/template';
import 'tinymce/plugins/textpattern';
import 'tinymce/plugins/visualblocks';
import 'tinymce/plugins/anchor';
import 'tinymce/plugins/autoresize';
// import 'tinymce/plugins/bbcode';
import 'tinymce/plugins/code';
import 'tinymce/plugins/directionality';
// import 'tinymce/plugins/fullpage';
// import 'tinymce/plugins/help';
import 'tinymce/plugins/image';
// import 'tinymce/plugins/importcss';
// import 'tinymce/plugins/legacyoutput';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/nonbreaking';
// import 'tinymce/plugins/pagebreak';
// import 'tinymce/plugins/preview';
import 'tinymce/plugins/save';
// import 'tinymce/plugins/spellchecker';
import 'tinymce/plugins/table';
// import 'tinymce/plugins/toc';
import 'tinymce/plugins/visualchars';

// Custom plugins
// Language
import '../tinymce/langs/pl';

// A theme is also required
import 'tinymce/themes/silver';

@Options({
    name: 'editor',
    components: {
        // 'tinymce': TinyMCE
    }
})
export default class Editor extends Vue
{
    @Prop()
    public id!: string;

    @Prop({ default: '' })
    public modelValue: string;

    @Prop({
        default: () => [
            'advlist autolink autosave lists link charmap hr anchor autoresize',
            'searchreplace wordcount visualblocks visualchars code template',
            'insertdatetime nonbreaking save table directionality',
            'paste textpattern fullscreen'
            // 'gallery filemanager mention localautosave'
        ]
    })
    private plugins: string[];

    @Prop({
        default: 'undo redo | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | insertLogoButton link gallery_insert filemanager_insert | forecolor backcolor | nonbreaking template fullscreen code'
    })
    private toolbar: string;

    @Prop({ default: () => {} })
    public editorOptions: Record<string, any>;

    private content: string = '';
    private modal: boolean = false;
    private media: string = "files";
    private filemanager: any = {
        enabled: false,
        callback: null,
        value: null,
        meta: null
    };

    private xssWhiteList: any = {
        iframe: ['width', 'height', 'src', 'frameborder', 'allow', 'allowfullscreen']
    };

    private xssIgnoredTag: string[] = ['img'];

    private xssOptions: xss.IFilterXSSOptions = {
        whiteList: merge(xss.getDefaultWhiteList(), this.xssWhiteList) as any,
        onIgnoreTagAttr: (tag: string, name: string, value: string, isWhiteAttr: any) =>
        {
            const allowed = ['id', 'style', 'class', 'contenteditable'];

            if (name.substr(0, 5) === "data-" || allowed.includes(name))
            {
                return `${name}="${value}"`;
            }
        },
        onTag: (tag:string, html:string, options:any) =>

        {
            if (this.xssIgnoredTag.some(el => el === tag)) return html;
        },
    };

    private editor(): any
    {
        return tinymce.get(this.id);
    }

    public async mounted(): Promise<void>
    {
        this.filemanager.enabled = await this.$permissions.all([Permissions.Storage.View]);
        this.$events.$on('filemanager::modal::close', this.closeModal);
        this.$events.$on('filemanager::callback::modelValue', this.callbackValue);

        tinymce.init(this.settings(this));
    }

    public unmounted(): void
    {
        tinymce.remove(this.editor());

        this.$events.$off('filemanager::modal::close', this.closeModal);
        this.$events.$off('filemanager::callback::modelValue', this.callbackValue);
    }

    private filterXSS(value: string): string
    {
        return xss.filterXSS(value, this.xssOptions);
    }

    private settings(ctx: any): any
    {
        const updateImage = (el: any): void =>
        {
            const src = el[0].getAttribute('src');

            if (src && src.indexOf('#(storage):') == 0)
            {
                const publicId = src.replace('#(storage):', '');

                el[0].setAttribute('src', StorageService.previewResource(publicId));
                el[0].setAttribute('data-src', src);
            }
        };

        const displayImages = (editor: any, content: string): string =>
        {
            const html = document.createElement('div');
            html.innerHTML = content;
            html.querySelectorAll('img').forEach(image =>
            {
                updateImage([image]);
            });
            return html.innerHTML;
        };

        const serializeImages = (editor: any, content: string): string =>
        {
            // Zablokowanie doczytywania obrazkow przez parser jquery
            content = content.replace(new RegExp(' src="', 'g'), ' src-disabled="');
            const html = document.createElement('div');
            html.innerHTML = content;
            html.querySelectorAll('img').forEach(image =>
            {
                const src = image.getAttribute('data-src');
                if (src && src.indexOf('#(storage):') == 0)
                {
                    image.setAttribute('src-disabled', src);
                    image.removeAttribute('data-src');
                }
            });
            return html.innerHTML.replace(new RegExp(' src-disabled="', 'g'), ' src="');
        };

        const options = {
            base_url: '/static/tinymce',
            selector: '#' + this.id,
            language: ctx.$i18n.shortLocale(),
            skin_url: '/static/skins/oxide',
            content_css: '/static/skins/oxide/content.min.css',
            mobile: {
                theme: 'silver'
            },
            toolbar: this.toolbar,
            toolbar_mode: 'floating',
            menubar: 'edit insert view format tools',
            plugins: this.plugins,
            templates: this.templates(),
            relative_urls: false,
            forced_root_block: false,
            paste_data_images: false,
            browser_spellcheck: true,
            entity_encoding: "raw",
            min_height: 450,
            autoresize_bottom_margin: 50,
            autoresize_on_init: true,
            nowrap: false,
            resize: false,
            table_style_by_css: true,
            media_alt_source: false,
            media_poster: false,
            image_advtab: true,
            convert_urls: false,
            floatingtoolbar_selectors: ['div.content.scroll'],
            // paste_preprocess: (plugin: any, args: any) =>
            // {
            //     const plainText = args.content.replace(/<[^>]*>?/gm, '');
            //     args.content = plainText;
            // },
            // las_seconds: 15,
            file_picker_callback: !this.filemanager.enabled ? null : (callback: any, value: any, meta: any) =>
            {
                ctx.modal = true;
                ctx.filemanager.callback = callback;
                ctx.filemanager.value = value;
                ctx.filemanager.meta = meta;

                switch (meta.filetype)
                {
                    case "image":
                        ctx.media = "images";
                        break;
                    case "media":
                        ctx.media = "media";
                        break;
                    case "file":
                        ctx.media = "files";
                        break;
                    default:
                        ctx.media = "files";
                        break;
                }
            },
            setup: (editor: any) =>
            {
                editor.on('init', (e: Event) =>
                {
                    ctx.content = this.filterXSS(this.modelValue);
                    editor.setContent(ctx.content);
                });
                ['change', 'keyup', 'undo', 'redo'].forEach(event =>
                {
                    editor.on(event, () =>
                    {
                        ctx.content = serializeImages(editor, editor.getContent());
                        ctx.updateModel(ctx.content);
                    });
                });
                editor.ui.registry.addButton('insertLogoButton', {
                    text: '',
                    icon: 'gallery',
                    tooltip: this.$t("[[[Wstaw dodane logo]]]"),
                    onAction: function()
                    {
                        editor.execCommand("mceInsertContent", false, `<span class='logo'>{{ logo }}</span>`);
                    }
                });
                editor.on('BeforeSetContent', (e: any) =>
                {
                    if (e.content)
                    {
                        e.content = this.filterXSS(e.content);
                        e.content = displayImages(editor, e.content);
                    }
                });
                editor.on('PostProcess', (e: any) =>
                {
                    if (e.content && e.source_view)
                    {
                        e.content = serializeImages(editor, e.content);
                    }
                    if (e.content && !e.source_view)
                    {
                        e.content = displayImages(editor, e.content);
                    }
                });
                editor.on('NodeChange', (e: any) =>
                {
                    if (e.element && e.element.tagName == "IMG")
                    {
                        const el = e.element;
                        const src = el.getAttribute('src');

                        if (src.startsWith('#(storage):'))
                        {
                            updateImage(e.element);
                        }
                    }
                });
                editor.on('OpenWindow', (e: any) =>
                {
                    const data = e.dialog.getData();

                    // Image
                    if (data?.src?.value?.length > 0 && data?.src?.value.startsWith(baseurl()))
                    {
                        e.dialog.setData({
                            src: {
                                value: `#(storage):${data.src.value.split('/').pop()}`
                            }
                        });
                    }
                });
            }
        };

        return merge(options, this.editorOptions);
    }

    @Emit('update:modelValue')
    private updateModel(content: string): string
    {
        return content;
    }

    private callbackValue(files: any[]): void
    {
        if (this.modal === true)
        {
            const file = head(files);
            const params = this.filemanager;

            if (file)
            {
                const url = `#(storage):${file.publicId}`;
                const size = this.$filters.filesize(file.contentLength);
                const text = `${file.name} [${size}]`;

                if (params.meta.filetype === 'file')
                {
                    params.callback(url, { text: params.meta.text || text, title: file.name });
                }
                if (params.meta.filetype === 'image')
                {
                    params.callback(url, { alt: file.name, width: file.meta.width, height: file.meta.height });
                }
                if (params.meta.filetype === 'media')
                {
                    params.callback(url, { width: 480, height: 270 });
                }
            }

            this.closeModal();
        }
    }

    private closeModal(): void
    {
        this.modal = false;
    }

    @Watch('modelValue')
    private onValueChanged(value: string, old: string): void
    {
        if (this.editor() && value !== this.content && value !== old)
        {
            value = this.filterXSS(value);
            this.editor().setContent(value);
            this.content = value;
        }
    }

    private templates(): any[]
    {
        return [
            {
                title: this.$t('[[[Pisma wychodzące - koperta]]]'),
                description: this.$t("[[[Wstaw szablon koperty]]]"),
                content: `
                    {% for item in result.contractors %}
                        <div style="font-family: Arial, Helvetica, sans-serif; height: 600px; position: relative; font-size: 20px;">
                            <div style="position: absolute; left: 0; top: 0; font-size: 18px;">
                                <b>Ideo</b><br/>
                                Sp z.o.o, 35-234 Rzeszów <br/>
                                ul. Nad Przyrwą 13
                            </div>
                            <div style="position: absolute; right: 0; top: 0;">
                                OPŁATA POBRANA <br/>
                                <div style="margin-left: 50px;">
                                    Umowa nr XXXXXXX/Y z Pocztą Polską S.A.<br/>
                                    &nbsp;&nbsp;z dnia 01.01.2022r.<br />
                                    Nadano z UP Rzeszów 1
                                </div>
                            </div>
                            <div>
                                <div style="position: absolute; left: 0; bottom: 0; text-align: center; margin-left: 100px;">
                                    POLECONY
                                </div>
                                <div style="position:absolute; right: 0; bottom: 0;">
                                    {{ item.name }}<br />
                                    {{ item.street_name }}<br />
                                    {{ item.street_postal }} {{ item.city }}<br />
                                </div>
                            </div>
                        </div>
                        {% unless result.contractors.last.public_id == item.public_id %}
                            <div style="page-break-after: always;"></div>
                        {% endunless %}
                    {% endfor %}
                `
            },
            {
                title: this.$t('[[[Korespondencja przychodząca]]]'),
                description: this.$t("[[[Wstaw szablon tabeli korespondecji przychodzącej]]]"),
                content: `
                    <div style="font-family: "Helvetica Neue", Helvetica, Arial; font-size: 14px; line-height: 20px; font-weight: 400; color: #3b3b3b; -webkit-font-smoothing: antialiased; font-smoothing: antialiased; background: white;">
                        <div style="border-top: 2px solid black; border-bottom: 2px solid black; width: 100%; height: 50px; font-size: large; font-weight: bold; text-align: center; box-sizing: border-box; padding: 12px 0;">
                            Pisma przychodzące - Ewidencja pism
                        </div>
                        <table style="table-layout:fixed; margin: 40px auto 0 auto; width: 98%; box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2); border-collapse: collapse; page-break-inside: auto;" border="1">
                            <tr style="page-break-inside: avoid;  page-break-after: auto;">
                                <th style="width:130px; text-align: center; background-color: darkgray; border: 1px solid black;">Nr kolejny</th>
                                <th style="width:130px; text-align: center; background-color: darkgray; border: 1px solid black;">Numer pisma</th>
                                <th style="width:250px; text-align: center; background-color: darkgray; border: 1px solid black;" rowspan="2">Od kogo</th>
                                <th style="width:350px; text-align: center; background-color: darkgray; border: 1px solid black;" rowspan="2">Treść otrzymanej korespondencji</th>
                                <th  style="width:100px; text-align: center; background-color: darkgray; border: 1px solid black;" rowspan="2">Numer</th>
                                <th  style="width:100px; text-align: center; background-color: darkgray; border: 1px solid black;" rowspan="2">Znak referenta</th>
                            </tr>
                            <tr style="page-break-inside: avoid;  page-break-after: auto;">
                                <th style="text-align: center; background-color: darkgray; border: 1px solid black;">Data nadania</th>
                                <th style="text-align: center; background-color: darkgray; border: 1px solid black;">Data wpłynięcia</th>
                            </tr>
                            <tr style="page-break-inside: avoid; page-break-after: auto;" data-for="item" data-array="result">
                                <td style="text-align: center; height: 50px; border: 1px solid black; overflow: hidden;">
                                    <div style="height: 100%;">
                                        <div style="height: 21px;">
                                            <span>{{ item.signature_number }}</span>
                                        </div>
                                        <div style="width: 100%; height: 1px; background-color: black; margin: 3px 0;"></div>
                                        <div style="height: 21px;">
                                            <span>{{ item.date_created_utc | date: "yyyy - MM - dd" }} </span>
                                        </div>
                                    </div>
                                </td>
                                <td style="text-align: center; height: 50px; border: 1px solid black; overflow: hidden;">
                                    <div style="height: 100%;">
                                        <div style="height: 21px;">
                                            <span>{{ item.signature }}</span>
                                        </div>
                                        <div style="width: 100%; height: 1px; background-color: black; margin: 3px 0;"></div>
                                        <div style="height: 21px;">
                                            <span>{{ item.date_receipt_utc | date: "yyyy - MM - dd" }}</span>
                                        </div>
                                    </div>
                                </td>
                                <td style="text-align: center; height: 50px; border: 1px solid black;"> {{ item.contractor }}</td>
                                <td style="text-align: center; height: 50px; border: 1px solid black;"> Temat: {{ item.title }} <br> Opis: {{ item.description }} </td>
                                <td style="text-align: center; height: 50px; border: 1px solid black; overflow: hidden;"> {{ item.external_number }} </td>
                            </tr>
                        </table>
                    </div>
                `
            },
            {
                title: this.$t("[[[Raport korespondencji wychodzącej]]]"),
                description: this.$t("[[[Wstaw szablon tabeli korespondecji wychodzącej]]]"),
                content: `
                    <div style="font-family: "Helvetica Neue", Helvetica, Arial;font-size: 14px;line-height: 20px;font-weight: 400;color: #000;-webkit-font-smoothing: antialiased;font-smoothing: antialiased;background: white; padding: 15px;">
                        <div style="border-bottom: 1px solid black;width: 100%;font-size: 36px; font-weight: bold;text-align: center;box-sizing: border-box;padding: 12px 0;">
                            Pocztowa książka nadawcza
                        </div>
                        <div style="margin-top: 40px; font-size: 24px; font-weight: bold; text-align: right;">
                            <span>Załącznik nr </span>
                            <span style="width: 200px; display: inline-block;border-bottom: 2px dotted black;">&nbsp;</span>
                        </div>
                        <div style="margin-top: 20px; font-size: 24px; font-weight: bold;">
                            <table style="table-layout: fixed;margin: 10px auto 0 auto;width: 100%;border-collapse: collapse;page-break-inside: auto;">
                                <tr>
                                    <td style="border: 2px solid black;text-align: center;vertical-align: top; text-align: left; width: 530px; font-size: 24px; font-weight: bold; font-size: 13px; padding: 3px;">Imię i nazwisko (nazwa) oraz adres nadawcy</td>
                                    <td style="border: 2px solid black;text-align: center;font-size: 13px; padding: 3px;">
                                        <div style="margin: 30px 0 40px 0;">
                                            <span style="width: 100%; display: inline-block;border-bottom: 2px dotted black;">&nbsp;</span>
                                            </div>&nbsp;<div>
                                            <span style="width: 100%; display: inline-block;border-bottom: 2px dotted black;">&nbsp;</span>
                                        </div>
                                    </td>
                                </tr>
                            </table>
                        </div>
                        <table style="table-layout: fixed;margin: 10px auto 0 auto;width: 100%;border-collapse: collapse;page-break-inside: auto;">
                            <thead>
                                <tr style="page-break-inside: avoid;page-break-after: auto;">
                                    <td rowspan="2" style="border: 2px solid black;text-align: center;width: 25px; font-size: 13px; padding: 3px;">Lp.</td>
                                    <td rowspan="2" style="border: 2px solid black;text-align: center;font-size: 13px; padding: 3px;">ADRESAT<br>(imię i nazwisko lub nazwa)</td>
                                    <td rowspan="2" style="border: 2px solid black;text-align: center;font-size: 13px; padding: 3px;">Dokładne miejsce doręczenia</td>
                                    <td colspan="2" style="border: 2px solid black;text-align: center;width: 100px; font-size: 13px; padding: 3px;">Kwota zadekl. wartości</td>
                                    <td colspan="2" style="border: 2px solid black;text-align: center;width: 100px; font-size: 13px; padding: 3px;">Masa</td>
                                    <td rowspan="2" style="border: 2px solid black;text-align: center;font-size: 13px; padding: 3px;">Nr nadawczy</td>
                                    <td rowspan="2" style="border: 2px solid black;text-align: center;font-size: 13px; padding: 3px;">Uwagi</td>
                                    <td colspan="2" style="border: 2px solid black;text-align: center;width: 100px; font-size: 13px; padding: 3px;">Opłata</td>
                                    <td colspan="2" style="border: 2px solid black;text-align: center;width: 100px; font-size: 13px; padding: 3px;">Kwota pobrania</td>
                                </tr>
                                <tr style="page-break-inside: avoid;page-break-after: auto;">
                                    <td style="border: 2px solid black;text-align: center;width: 70%; border-right-width: 1px; font-size: 13px; padding: 3px;">zł</td>
                                    <td style="border: 2px solid black;text-align: center;border-left-width: 1px; width: 30%; font-size: 13px; padding: 3px;">gr</td>
                                    <td style="border: 2px solid black;text-align: center;border-right-width: 1px; font-size: 13px; padding: 3px;">kg</td>
                                    <td style="border: 2px solid black;text-align: center;border-left-width: 1px; font-size: 13px; padding: 3px;">g</td>
                                    <td style="border: 2px solid black;text-align: center;border-right-width: 1px; font-size: 13px; padding: 3px;">zł</td>
                                    <td style="border: 2px solid black;text-align: center;border-left-width: 1px; font-size: 13px; padding: 3px;">gr</td>
                                    <td style="border: 2px solid black;text-align: center;border-right-width: 1px; font-size: 13px; padding: 3px;">zł</td>
                                    <td style="border: 2px solid black;text-align: center;border-left-width: 1px; font-size: 13px; padding: 3px;"">gr</td>
                                </tr>
                                <tr style="page-break-inside: avoid;page-break-after: auto;">
                                    <td style="border: 2px solid black;text-align: center;font-size: 13px; padding: 3px;">1</td>
                                    <td style="border: 2px solid black;text-align: center;font-size: 13px; padding: 3px;">2</td>
                                    <td style="border: 2px solid black;text-align: center;font-size: 13px; padding: 3px;">3</td>
                                    <td colspan="2" style="border: 2px solid black;text-align: center;font-size: 13px; padding: 3px;">4</td>
                                    <td colspan="2" style="border: 2px solid black;text-align: center;font-size: 13px; padding: 3px;">5</td>
                                    <td style="border: 2px solid black;text-align: center;font-size: 13px; padding: 3px;">6</td>
                                    <td style="border: 2px solid black;text-align: center;font-size: 13px; padding: 3px;">7</td>
                                    <td colspan="2" style="border: 2px solid black;text-align: center;font-size: 13px; padding: 3px;">8</td>
                                    <td colspan="2" style="border: 2px solid black;text-align: center;font-size: 13px; padding: 3px;">9</td>
                                </tr>
                                <tr style="page-break-inside: avoid;page-break-after: auto;">
                                    <td colspan="5" style="border: 2px solid black;text-align: center;padding-right: 30px; text-align: right; font-size: 13px; padding: 3px;">Z przeniesienia</td>
                                    <td style="border: 2px solid black;text-align: center;border-right-width: 1px; font-size: 13px; padding: 3px;"></td>
                                    <td style="border: 2px solid black;text-align: center;border-left-width: 1px; font-size: 13px; padding: 3px;"></td>
                                    <td colspan="2" style="border: 2px solid black;text-align: center;padding-right: 30px; text-align: right; font-size: 13px; padding: 3px;">Z przeniesienia</td>
                                    <td style="border: 2px solid black;text-align: center;border-right-width: 1px; font-size: 13px; padding: 3px;"></td>
                                    <td style="border: 2px solid black;text-align: center;border-left-width: 1px; font-size: 13px; padding: 3px;"></td>
                                    <td style="border: 2px solid black;text-align: center;border-right-width: 1px; font-size: 13px; padding: 3px;"></td>
                                    <td style="border: 2px solid black;text-align: center;border-left-width: 1px; font-size: 13px; padding: 3px;"></td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr style="page-break-inside: avoid;page-break-after: auto;" data-for="item" data-array="result">
                                    <td style="border: 2px solid black;text-align: center;font-size: 13px; padding: 3px;"></td>
                                    <td style="border: 2px solid black;text-align: center;font-size: 13px; padding: 3px;">{{item.contractor.name}}</td>
                                    <td style="border: 2px solid black;text-align: center;font-size: 13px; padding: 3px;">{{item.contractor.street_postal}}{{item.contractor.city}},{{item.contractor.street_name}}{{item.contractor.building}}</td>
                                    <td style="border: 2px solid black;text-align: center;border-right-width: 1px; font-size: 13px; padding: 3px;"></td>
                                    <td style="border: 2px solid black;text-align: center;border-left-width: 1px; font-size: 13px; padding: 3px;"></td>
                                    <td style="border: 2px solid black;text-align: center;border-right-width: 1px; font-size: 13px; padding: 3px;"></td>
                                    <td style="border: 2px solid black;text-align: center;border-left-width: 1px; font-size: 13px; padding: 3px;"></td>
                                    <td style="border: 2px solid black;text-align: center;font-size: 13px; padding: 3px;">{{item.number}}</td>
                                    <td style="border: 2px solid black;text-align: center;font-size: 13px; padding: 3px;">{{item.comment}}</td>
                                    <td style="border: 2px solid black;text-align: center;border-right-width: 1px; font-size: 13px; padding: 3px;"></td>
                                    <td style="border: 2px solid black;text-align: center;border-left-width: 1px; font-size: 13px; padding: 3px;"></td>
                                    <td style="border: 2px solid black; text-align: center;border-right-width: 1px; font-size: 13px; padding: 3px;"></td>
                                    <td style="border: 2px solid black;text-align: center; border-left-width: 1px; font-size: 13px; padding: 3px;"></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                `
            }
        ];
    }
}
