import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "editor" }
const _hoisted_2 = ["id"]
const _hoisted_3 = {
  key: 0,
  class: "app-modal"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_filemanager = _resolveComponent("filemanager")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", { id: _ctx.id }, null, 8, _hoisted_2),
    (_ctx.modal)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(_component_filemanager, {
            modal: true,
            media: _ctx.media
          }, null, 8, ["media"])
        ]))
      : _createCommentVNode("", true)
  ]))
}